.findYourNext {
  margin: 0;
}
.findYourNextContainer {
  flex: 1;
  position: relative;
  white-space: pre-wrap;
  text-shadow: 0 2px 20px rgba(0, 63, 145, 0.42);
  -webkit-text-stroke: 1px #e5e5e5;
}
.findYourNextCareerMoveWitWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-3xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-17xl);
}
.partnerWithUs {
  flex: 1;
  position: relative;
}
.partnerWithUsForExpertRecWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-xl) 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.findTheRight {
  position: relative;
}
.findTheRightJobForYouWrapper {
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini);
  align-items: center;
  justify-content: center;
}
.frameParent,
.homePageInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  align-self: stretch;
  flex: 1;
}
.homePageInner {
  position: absolute;
  width: 101.96%;
  top: 6.19rem;
  right: -1.01%;
  left: -0.94%;
  height: 45rem;
  padding: var(--padding-174xl) var(--padding-11xl);
  box-sizing: border-box;
  background-image: url(/public/frame-46@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-prompt);
}
@media screen and (max-width: 960px) {
  .homePageInner {
    display: flex;
  }
}
@media screen and (max-width: 420px) {
  .homePageInner {
    display: flex;
  }
}

.contactUs1 {
  position: absolute;
  top: 0;
  left: 20.15%;
  font-size: var(--font-size-5xl);
}
.b,
.infobigtreegroupca {
  position: absolute;
  top: 3.56rem;
  left: 20.88%;
}
.infobigtreegroupca {
  top: 8rem;
}
.messageIcon,
.phoneIcon {
  position: absolute;
  object-fit: cover;
  cursor: pointer;
}
.phoneIcon {
  top: 2.75rem;
  left: -0.43rem;
  width: 3.38rem;
  height: 3.44rem;
}
.messageIcon {
  width: 15.02%;
  top: 7.94rem;
  right: 84.45%;
  left: 0.54%;
  max-width: 100%;
  overflow: hidden;
  height: 1.81rem;
}
.contactUsParent {
  position: relative;
  width: 17.06rem;
  height: 10.25rem;
}
.contactUs {
  position: absolute;
  top: 1.88rem;
  left: calc(50% - 107.37px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.linkdlnIcon {
  position: absolute;
  top: 14.25rem;
  left: calc(50% - 1.3px);
  width: 3.06rem;
  height: 3.06rem;
  object-fit: cover;
  cursor: pointer;
}
.footer {
  position: absolute;
  width: 129.25%;
  top: 151.94rem;
  right: -10.7%;
  left: -18.55%;
  background-color: var(--color-darkslategray);
  height: 19rem;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-snow);
  font-family: var(--font-open-sans);
}

.logoIcon {
  position: absolute;
  top: 1.13rem;
  left: 1.88rem;
  width: 13.56rem;
  height: 5.06rem;
  object-fit: cover;
}
.aboutUs,
.contactUs,
.home {
  position: relative;
  cursor: pointer;
}
.aboutUs,
.contactUs {
  display: inline-block;
  width: 5.56rem;
  flex-shrink: 0;
}
.contactUs {
  width: 8.06rem;
}
.frame,
.navbar {
  display: flex;
  flex-direction: row;
}
.frame {
  width: 23.19rem;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.navbar {
  position: absolute;
  top: 1.88rem;
  left: calc(50% - 30.5px);
  width: 31.06rem;
  padding: var(--padding-7xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: space-between;
}
.image11Icon {
  position: relative;
  width: 2.38rem;
  height: 2.25rem;
  object-fit: cover;
}
.hamburger {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 2rem;
  right: 1.94rem;
  width: 2.38rem;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.aboutUs1 {
  flex: 1;
  position: relative;
  display: inline-block;
  height: 3.88rem;
  -webkit-text-stroke: 1px #000;
}
.heading {
  align-self: stretch;
  height: 5.63rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-3xs) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.atBigtreeRecruitment {
  margin: 0;
}
.aboutUsTextContainer {
  flex: 1;
  position: relative;
}
.aboutUs2,
.aboutUsPage,
.aboutUsTextWrapper {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.aboutUsTextWrapper {
  flex: 1;
  flex-direction: row;
}
.aboutUs2,
.aboutUsPage {
  flex-direction: column;
}
.aboutUs2 {
  flex: 1;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray);
  font-family: var(--font-prompt);
}
.aboutUsPage {
  background-color: var(--color-white);
  height: 34.81rem;
  padding: var(--padding-xl) 0.98rem;
  box-sizing: border-box;
  text-align: center;
  font-size: 2.5rem;
  color: var(--color-black);
  font-family: var(--font-public-sans);
}
.construction {
  position: relative;
  font-weight: 600;
  display: inline-block;
  width: 14.63rem;
  height: 3.06rem;
  flex-shrink: 0;
}
.cardConstruction,
.cardTechnology {
  width: 23.44rem;
  height: 13.31rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-49xl) 3.08rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background-image: url(/public/card-construction@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  cursor: pointer;
}
.cardTechnology {
  width: 23.88rem;
  background-image: url(/public/card-technology@3x.png);
}
.finance {
  position: relative;
  font-weight: 600;
  display: inline-block;
  width: 10.5rem;
  height: 3.06rem;
  flex-shrink: 0;
}
.aboutUsPageParent,
.cardFinance {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardFinance {
  width: 23.88rem;
  height: 13.31rem;
  padding: var(--padding-49xl) 3.08rem;
  box-sizing: border-box;
  justify-content: center;
  background-image: url(/public/card-finance@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  cursor: pointer;
}
.aboutUsPageParent {
  position: absolute;
  width: 97.22%;
  top: 53.13rem;
  right: 1.35%;
  left: 1.43%;
  height: 94.44rem;
  justify-content: flex-start;
  gap: 3.13rem;
  font-size: var(--font-size-17xl);
  color: var(--color-white);
}
.homePage {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 170.94rem;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray);
  font-family: var(--font-open-sans);
}
@media screen and (max-width: 1200px) {
  .hamburger {
    display: none;
    opacity: 1;
  }
  .hamburger.animate {
    animation: 1s ease 0s 1 normal forwards scale-up;
  }
  @keyframes scale-up {
    0% {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
  .cardConstruction,
  .cardFinance,
  .cardTechnology {
    display: flex;
  }
}
@media screen and (max-width: 960px) {
  .navbar {
    display: none;
    cursor: default;
  }
  .cardConstruction,
  .cardFinance,
  .cardTechnology,
  .hamburger {
    display: flex;
  }
}
@media screen and (max-width: 420px) {
  .navbar {
    display: none;
  }
  .cardConstruction,
  .cardFinance,
  .cardTechnology,
  .hamburger {
    display: flex;
  }
}
@media screen and (max-width: 350px) {
  .cardConstruction,
  .cardFinance,
  .cardTechnology {
    display: flex;
  }
}

.contactUs1 {
  position: absolute;
  top: 0;
  left: 20.15%;
  font-size: var(--font-size-5xl);
}
.b,
.infobigtreegroupca {
  position: absolute;
  top: 3.56rem;
  left: 20.88%;
}
.infobigtreegroupca {
  top: 8rem;
}
.messageIcon,
.phoneIcon {
  position: absolute;
  object-fit: cover;
  cursor: pointer;
}
.phoneIcon {
  top: 2.75rem;
  left: -0.43rem;
  width: 3.38rem;
  height: 3.44rem;
}
.messageIcon {
  width: 11.36%;
  top: 8.13rem;
  right: 85.71%;
  left: 2.93%;
  max-width: 100%;
  overflow: hidden;
  height: 1.38rem;
}
.contactUsParent {
  position: relative;
  width: 17.06rem;
  height: 10.25rem;
}
.contactUs {
  position: absolute;
  top: 6.19rem;
  left: calc(50% - 133px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.linkdlnIcon {
  position: absolute;
  top: 19.19rem;
  left: calc(50% - 27px);
  width: 3.06rem;
  height: 3.06rem;
  object-fit: cover;
  cursor: pointer;
}
.home {
  position: relative;
}
.homeWrapper {
  position: absolute;
  top: 2.06rem;
  left: 16.94rem;
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-100);
  height: 2.25rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-prompt);
}
.contact {
  margin: 0;
  position: relative;
  background-color: var(--color-darkslategray);
  width: 100%;
  height: 26.06rem;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-snow);
  font-family: var(--font-open-sans);
}

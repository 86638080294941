@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-open-sans: "Open Sans";
  --font-prompt: Prompt;
  --font-public-sans: "Public Sans";

  /* font sizes */
  --font-size-17xl: 2.25rem;
  --font-size-sm: 0.88rem;
  --font-size-xl: 1.25rem;
  --font-size-5xl: 1.5rem;
  --font-size-29xl: 3rem;

  /* Colors */
  --color-white: #fff;
  --color-darkslategray: #373a3e;
  --color-black: #000;
  --color-snow: #fffcfc;
  --color-gray-100: #797979;

  /* Gaps */
  --gap-29xl: 3rem;
  --gap-262xl: 17.56rem;

  /* Paddings */
  --padding-49xl: 4.25rem;
  --padding-30xl-3: 3.08rem;
  --padding-xl: 1.25rem;
  --padding-3xs: 0.63rem;
  --padding-7xs: 0.38rem;
  --padding-174xl: 12.06rem;
  --padding-11xl: 1.88rem;
  --padding-mini: 0.94rem;
  --padding-21xl: 2.5rem;

  /* border radiuses */
  --br-3xs: 10px;
}

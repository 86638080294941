@keyframes slide-in-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.aboutUs,
.contactUs,
.home {
  position: absolute;
  left: 3.75rem;
  cursor: pointer;
}
.home {
  top: 9.38rem;
  color: #1e1e1e;
}
.aboutUs,
.contactUs {
  top: 14.31rem;
}
.contactUs {
  top: 19.81rem;
}
.drawerMenu {
  position: relative;
  background-color: var(--color-white);
  width: 26.56rem;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  max-width: 90%;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-open-sans);
}
.drawerMenu.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-right;
}

.cardTechnologyIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 19.88rem;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}
.technology1 {
  flex: 1;
  position: relative;
  -webkit-text-stroke: 1px #e5e5e5;
}
.technologyWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-3xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-family: var(--font-public-sans);
}
.blankLine {
  margin: 0;
}
.inTheEverEvolving {
  margin: 0;
  font-family: var(--font-prompt);
}
.inTheEverEvolvingContainer {
  flex: 1;
  position: relative;
}
.inTheEverEvolvingWorldOfWrapper,
.technologyInner {
  align-self: stretch;
  height: 40.13rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-xl) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.technologyInner {
  height: 41.75rem;
  flex-direction: column;
  padding: 0 var(--padding-3xs);
  font-size: var(--font-size-sm);
}
.b,
.contactUs1 {
  position: absolute;
}
.contactUs1 {
  top: 0;
  left: 20.15%;
  font-size: var(--font-size-5xl);
}
.b {
  top: 34.76%;
  left: 3.56rem;
}
.infobigtreegroupca {
  position: absolute;
  top: 8rem;
  left: 20.88%;
}
.messageIcon,
.phoneIcon {
  position: absolute;
  top: 2.75rem;
  left: -0.43rem;
  width: 3.38rem;
  height: 3.44rem;
  object-fit: cover;
  cursor: pointer;
}
.messageIcon {
  top: 7.94rem;
  left: 0.09rem;
  width: 2.56rem;
  height: 1.81rem;
}
.contactUsParent {
  flex: 1;
  position: relative;
  width: 17.06rem;
}
.contactUs {
  position: absolute;
  top: 1.88rem;
  left: calc(50% - 107.46px);
  height: 10.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.linkdlnIcon {
  position: absolute;
  top: 13.56rem;
  left: calc(50% - 1px);
  width: 3.06rem;
  height: 3.06rem;
  object-fit: cover;
  cursor: pointer;
}
.footer {
  align-self: stretch;
  position: relative;
  background-color: var(--color-darkslategray);
  height: 18.25rem;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--font-size-xl);
  color: var(--color-snow);
}
.technology {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 92.19rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-21xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-29xl);
  color: var(--color-black);
  font-family: var(--font-open-sans);
}

.logoIcon {
  position: relative;
  width: 13.56rem;
  height: 5.06rem;
  object-fit: cover;
  z-index: 0;
}
.aboutUs,
.contactUs,
.home {
  position: relative;
  cursor: pointer;
}
.aboutUs,
.contactUs {
  display: inline-block;
  width: 5.56rem;
  flex-shrink: 0;
}
.contactUs {
  width: 8.06rem;
}
.frame,
.navbar {
  display: flex;
  flex-direction: row;
}
.frame {
  width: 23.19rem;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.navbar {
  width: 31.06rem;
  padding: var(--padding-7xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1;
}
.image11Icon {
  position: relative;
  width: 2.38rem;
  height: 2.25rem;
  object-fit: cover;
}
.hamburger {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  margin: 0 !important;
  position: absolute;
  top: 1.44rem;
  right: 2.06rem;
  width: 2.38rem;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.logoParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-262xl);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray);
  font-family: var(--font-open-sans);
}
@media screen and (max-width: 1200px) {
  .hamburger {
    display: none;
    opacity: 1;
  }
  .hamburger.animate {
    animation: 1s ease 0s 1 normal forwards scale-up;
  }
  @keyframes scale-up {
    0% {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
}
@media screen and (max-width: 960px) {
  .navbar {
    display: none;
    cursor: default;
  }
  .hamburger {
    display: flex;
  }
}
@media screen and (max-width: 420px) {
  .navbar {
    display: none;
  }
  .hamburger {
    display: flex;
  }
}
